<template lang="html">
  <div class="walls">
    <div class="steps__heading">
      <Heading :hType="3" text="Steinauswahl" textAlign="center" />
      <Heading :hType="1" text="Gemäuer" textAlign="center" />
    </div>
    <div class="steps__description">Wählen Sie einen Bereich:</div>
    <div class="walls">
      <div class="wall" v-for="wall in walls" :key="wall.id">
        <RadioPanel :inputValue="wall.name" v-model="selectedWall">
          <img
            v-if="wall.image"
            class="wall__image"
            :src="wall.image.sizes['medium_large']"
            :alt="wall.image.alt"
          />
          <div class="wall__text">
            <span class="wall__title">{{ wall.name }}</span>
            <span class="wall__description" v-html="wall.description"></span>
          </div>
        </RadioPanel>
      </div>
    </div>
    <div class="walls__button">
      <Button backgroundColor="#C99D66" @click="nextStep">
        <span v-if="globalSettings">
          {{ globalSettings['button_names']['choose_walls'] }}
        </span>
      </Button>
    </div>
  </div>
</template>

<script>
import Button from '@/components/Button/Button';
import Heading from '@/components/Heading/Heading';

import RadioPanel from '@/components/RadioPanel/RadioPanel';

export default {
  name: 'Walls',
  components: { Heading, Button, RadioPanel },
  data() {
    return {
      selectedWall: '',
    };
  },
  computed: {
    walls() {
      return this.$store.getters['stones/getWalls'];
    },
    globalSettings() {
      return this.$store.state.global.settings;
    },
  },
  methods: {
    nextStep() {
      if (this.selectedWall) {
        this.$store.commit('stones/setCurrentStep', 3);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'styles';
</style>
