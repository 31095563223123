<template lang="html">
  <label class="radio-panel">
    <input type="radio" :value="inputValue" v-model="model" />
    <span class="radio-panel__label">
      <slot></slot>
    </span>
  </label>
</template>

<script>
export default {
  name: 'RadioPanel',
  model: {
    prop: 'modelValue',
    event: 'change',
  },
  props: {
    inputValue: {
      type: [String, Object],
      required: true,
    },
    modelValue: { default: '' },
  },
  emits: ['update:modelValue'],
  computed: {
    model: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'styles';
</style>
